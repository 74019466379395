import React from "react";
import { Formik } from "formik";

interface IAppFormProps {
    initialValues: any;
    validationSchema: any;
    onSubmit: (data: any) => void;
    children: React.ReactNode;
}

const AppForm: React.FC<IAppFormProps> = ({ initialValues, validationSchema, onSubmit, children }) => {
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({ handleSubmit }) => (
                <>
                    <form
                        action="#"
                        method="POST"
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit(e);
                        }}
                    >
                        {children}
                    </form>
                </>
            )}
        </Formik>
    );
};

export default AppForm;
