import { useCallback, useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";

const useRedirectToStripe = (returnUrl?: string | null) => {
  const { subscription, user, products } = useContext(AuthContext);

  const handleOpenStripe = useCallback(async (priceId?: string) => {
    try {
      let openUrl;

      if (!user?.email || !products?.length) return;

      if (subscription?.customer && !priceId) {
        const customerPortalRes = await fetch(`/api/create-customer-portal-session`, {
          method: 'POST',
          body: JSON.stringify({
            customerId: subscription.customer
          })
        });
        const customerPortal = await customerPortalRes.json();
        openUrl = customerPortal?.sessionUrl;
      } else if (priceId) {
        const body = {
          email: user.email,
          successUrl: returnUrl,
          cancelUrl: returnUrl,
          priceId
        };

        if (!returnUrl) {
          delete body.successUrl;
          delete body.cancelUrl;
        }

        const checkoutRes = await fetch(`/api/create-checkout-session`, {
          method: 'POST',
          body: JSON.stringify(body)
        });
        const checkout = await checkoutRes.json();
        openUrl = checkout?.sessionUrl;
      }

      if (!openUrl) throw new Error('Cannot load stripe session! Reload app and try again');

      window.open(openUrl, '_self');
    } catch (err) {
      console.error(err);
      return;
    }
  }, [user, subscription, products, returnUrl]);

  return handleOpenStripe;
};

export default useRedirectToStripe;
