async function updateStripeCustomer(customerId, email) {
    const res = await fetch(`/api/update-customer-email`, {
        method: 'POST',
        body: JSON.stringify({
            email,
            customerId
        })
    });
    const status = !!(await res.json())?.updated;

    if (!status) throw new Error('Customer is not updated'); 
}

export default updateStripeCustomer;
